import React from "react";
import { Col, Row } from "react-bootstrap";
import { useFetch } from "../hooks/useFetch";
import Top20List from "./Top20List";
import "./css/TripList.css";
import Jumbo from "./Jumbo";
import { useParams } from "react-router-dom";

export default function Top20() {
  let params = useParams();
  let eventName = (params.eventName);
  console.log("Top20.....", eventName);
  let url = 'https://ctkeane-duck-api.herokuapp.com/top20/' + eventName;
  const top20Label = "Top 20 Best Times"

  const {
    data: topData,
    isPending,
    error,
  } = useFetch(url);
  console.log("topData.....", url, topData);

  return (
    <div className="App container">
    <div className="RecordBoard trip-list">
      <Row>
        <Jumbo top20={top20Label}></Jumbo>
      </Row>
      <Row>
        <Col md="12">
          {isPending && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {topData && (
            <Top20List data={topData} event={eventName} />
          )}
        </Col>
      </Row>
    </div>
    </div>
  );
}
