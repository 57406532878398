import React, { useState } from "react";
import { Table, Card, Col, Row, Button } from "react-bootstrap";

import "./css/TripList.css";

export default function Top20List(props) {
  const [inData] = useState(props.data);
  console.log("top20List", props.event, props);
  
  return (
    <div className="trip-list container">
      <Row>
      <Col md="12">
        <Card>
        <Card.Title>{props.event}</Card.Title>
      <Table striped responsive >
        <thead>
          <tr>
          <th scope="row">Place</th>
          <th scope="row">Year</th>
          <th scope="row">Time</th>
          <th scope="row">Swimmers</th>
          </tr>
        </thead>

    <tbody>
        {inData && inData.map((data, key) => {
              return (
          <tr key={key}>
            <td className="align-middle">{data.Place}</td>
            <td className="align-middle">{data.SwimYear}</td>
            <td className="align-middle">{data.SwimTime}</td>
            <td className="align-middle text-responsive">
              {data.Swimmer}
              {data.Swimmer2 && (
                <>
                  <br></br>
                  {data.Swimmer2}
                  <br></br>
                  {data.Swimmer3}
                  <br></br>
                  {data.Swimmer4}
                </>
              )}
            </td>
          </tr>
              )
        })} 

        </tbody>  
      </Table>
      </Card>
      </Col>
      </Row>
      <br></br>
      <Button variant="primary" href="http://swim.ctkeane.com">Back</Button>
    </div>
  );
}
