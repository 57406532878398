import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { projectFirestore } from "../firebase/config";

import "./css/TripList.css";
import RecordBoard from "./RecordBoard";

export default function RecordList() {
  //const { data: bData, isPending: isPendingB, error: errorB } = useFetch("http://localhost:3000/records?gender=Boys");
  //const { data: gData, isPending: isPendingG, error: errorG } = useFetch("http://localhost:3000/records?gender=Girls");

  const [bData, setBdata] = useState(null);
  const [gData, setGdata] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsPending(true);
    
    const unsub = projectFirestore.collection("board").orderBy("order").onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          setError("No records found!");
          setIsPending(false);
        } else {
          let bresults = [];
          let gresults = [];
          snapshot.docs.forEach((doc) => {
             if (doc.data().gender.toLowerCase() === "boys") {
              bresults.push({ id: doc.id, ...doc.data() });
             }
             else gresults.push({ id: doc.id, ...doc.data() });
            //    console.log(doc)
          });
          setBdata(bresults);
          setGdata(gresults);
          setIsPending(false);
        }
      },
      (err) => {
        setError(err.message);
        setIsPending(false);
      }
    );

    return() => unsub()

  }, []);

  return (
    <div className="trip-list container">
     
          <Row>
          <Col md="6">
           {isPending && <p>Loading...</p>}
           {error && <p>{error}</p>}
            { bData && <RecordBoard data={bData} gender="Boys"/>}
        </Col>
        <Col md="6">
           {isPending && <p>Loading...</p>}
           {error && <p>{error}</p>}
            { gData && <RecordBoard data={gData} gender="Girls"/>}
        </Col>
        </Row>
      </div>
  );
}
