import React from 'react';
import '../App.css';
import RecordList from './RecordList';
import Jumbo from './Jumbo';

function Board() {
  return (
    <>
    <div className="App container">
      <Jumbo />
      <RecordList />
    </div>
</>
);
}

export default Board;
