import React from "react";

const Jumbo = (props) => {
  return (
    <div>
      <div className="jumbotron p-3 p-md-5 text-grey rounded ">
        <div className="d-none d-lg-block">
          <h3 className="display-3">
            <img className="Image" src="../img/pomp_icon.png" alt="PHS"></img>
            Pomperaug High School
            <img
              className="Image "
              src="../img/pomp_icon.png"
              alt="PHS-Right"></img>
          </h3>
          
        </div>
        <h4 className="row justify-content-md-center">Swimming Record Board {props.top20} </h4>
        <div className="d-lg-none">
          <h4 className="display-3">
            <img className="Image" src="../img/pomp_icon.png" alt="PHS"></img>
            <br></br>
            <span>Pomperaug</span>
            <br></br>
            <span>High School</span>
          </h4>
          <h5>Swimming Record Board</h5>
        </div>

        
      </div>
    </div>
  );
};

export default Jumbo;
