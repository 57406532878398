import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Board from "./components/Board";
import Top20 from "./components/Top20";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Board />}></Route>
        <Route exact path="/top20/:eventName" element={<Top20></Top20>} />
        {/* <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
