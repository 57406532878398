import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
//const { Client } = require('pg')


const firebaseConfig = {
  apiKey: "AIzaSyCQ41iGhrh9jyAFLP_IDu2KLhxaNsyOVaA",
  authDomain: "cooking-ninja-site-ac89d.firebaseapp.com",
  projectId: "cooking-ninja-site-ac89d",
  storageBucket: "cooking-ninja-site-ac89d.appspot.com",
  messagingSenderId: "647519229975",
  appId: "1:647519229975:web:cfee939c92445c0884a452",
};

//init firebase
firebase.initializeApp(firebaseConfig);

//init services
const projectFirestore = firebase.firestore();
//const projectAuth = firebase.auth();
const projectAuth = firebase.auth().signInAnonymously()
  .then((user) => {
    // Signed in..
    var uid = user.uid;
    console.log("anon user id: " + uid);
  })
  .catch((error) => {
    var errorCode = error.code;
    var errorMessage = error.message;
    console.log("error: " + errorMessage, errorCode);
    // ...
  });

// const cockroachConfigClient = new Client({
//   host: 'my.database-server.com',
//   port: 5334,
//   user: 'database-user',
//   password: 'secretpassword!!',
// })

export { projectFirestore
    , projectAuth
   // , cockroachConfigClient 
  };
