import React, { useState } from "react";
import { Table, Card } from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";

export default function RecordBoard(props) {
  const [inData] = useState(props.data);

  function linkMe(event) {
    let eventName = event.event;
    let gender = event.gender;
    if (gender === "Girls") {
      return (
        <div style={{ display: "block", margin: "1rem 0" }}>{eventName}</div>
      );
    }
    return (
      <>
        <Link
          style={{ display: "block", margin: "1rem 0" }}
          to={`/top20/${eventName}`}
          key={eventName}>
          {eventName}
        </Link>

        <Outlet />
      </>
    );
  }

  return (
    <div>
      <Card>
        <Card.Title>{props.gender}</Card.Title>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Event</th>
              <th>Year</th>
              <th>Time</th>
              <th>Swimmers</th>
            </tr>
          </thead>

          <tbody>
            {inData &&
              inData.map((data, key) => {
                return (
                  <tr key={key}>
                    <td className="align-middle">{linkMe(data)}</td>
                    <td className="align-middle">{data.yearSet}</td>
                    <td className="align-middle">{data.time}</td>
                    <td className="align-middle text-responsive">
                      {data.swimmer[0]}
                      {!data.individual && (
                        <>
                          <br></br>
                          {data.swimmer[1]}
                          <br></br>
                          {data.swimmer[2]}
                          <br></br>
                          {data.swimmer[3]}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    </div>
  );
}
